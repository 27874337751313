<template>
  <v-main>
    <v-container fluid class="fullsize-container" id="top" v-if="loaded">
      <div class="lang-switch-container zfix">
        <LangSwitch :icon="false" />
      </div>
      <v-row style="height: 100%">
        <v-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
          xs="12"
          class="col-xs-10 zfix"
          align-self="center"
        >
          <div id="content-div">
            <div class="container-row">
              <LoginContent :loggedIn="isLoggedIn" v-if="page == 1" />
              <KeyFeatures v-if="page == 2" />
              <UseCases v-if="page == 3" />
              <HelpPage v-if="page == 4" />
            </div>
          </div>
        </v-col>

        <v-col cols="12" lg="4" md="4" class="pcc d-sm-none d-md-inline zfix">
          <div class="phone-container" v-if="!whiteLabel">
            <div class="phone" v-if="page != 4"></div>

            <a
              :if="SO_API_BASE_URL != undefined"
              :href="SO_API_BASE_URL"
              v-else
            >
              <div class="phone-api"></div>
            </a>
          </div>
        </v-col>

        <v-col
          cols="12"
          lg="1"
          md="1"
          sm="6"
          xs="6"
          align-self="center"
          style="text-align: left"
          class="order-md-first max-50 zfix"
        >
          <v-btn
            fab
            small
            dark
            :color="nextBtnColor"
            class="mx-2 button-breathing"
            type="button"
            @click="previousPage"
            v-if="!whiteLabel"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          lg="1"
          md="1"
          sm="6"
          xs="6"
          align-self="center"
          style="text-align: right"
          class="max-50 justify-space-between zfix"
        >
          <v-btn
            fab
            small
            dark
            :color="nextBtnColor"
            class="mx-2 button-breathing"
            type="button"
            @click="nextPage"
            v-if="!whiteLabel"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
        <SoParticles />
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import LoginContent from "@/components/landing/LoginContent";
import KeyFeatures from "@/components/landing/KeyFeatures";
import UseCases from "@/components/landing/UseCases";
import HelpPage from "@/components/landing/HelpPage";
import LangSwitch from "@/components/company/LangSwitch";
import SoParticles from "@/components/common/SoParticles";

export default {
  name: "LandingPage",
  data() {
    return {
      nextBtnColor: "#7ca6d8",
      page: 1,
      totalPages: 4,
      apiUrl: this.SO_API_BASE_URL,
      isLoggedIn: false,
      loaded: false,
    };
  },
  components: {
    LoginContent,
    KeyFeatures,
    UseCases,
    HelpPage,
    LangSwitch,
    SoParticles,
  },

  computed: {
    ...mapState("configuration", ["SO_API_BASE_URL", "license"]),
    ...mapState("users", { userStatus: "status" }),
  },

  async created() {
    await this.getLicenseTerms();
    if (this.userStatus) {
      this.isLoggedIn = this.userStatus.loggedIn;
    } else {
      this.isLoggedIn = false;
    }
    this.loaded = true;
  },

  methods: {
    ...mapActions("configuration", ["getLicenseTerms"]),

    nextPage() {
      let p = this.page + 1;
      if (p > this.totalPages) {
        this.page = 1;
      } else {
        this.page = p;
      }
      this.$scrollTo("#top", 4, {
        container: "#top",
        easing: "ease-in",
        offset: -60,
        force: true,
        x: false,
        y: true,
      });
    },

    previousPage() {
      let p = this.page - 1;
      if (p < 1) {
        this.page = this.totalPages;
      } else {
        this.page = p;
      }
      this.$scrollTo("#top", 4, {
        container: "#top",
        easing: "ease-in",
        offset: -60,
        force: true,
        x: false,
        y: true,
      });
    },
  },
};
</script>

<style scoped>
.lang-switch-container {
  position: fixed;
  width: 100px;
  top: 0;
  right: 0;
  margin: 2rem;
  color: white;
}

.max-50 {
  max-width: 50% !important;
}

.so-title {
  font-size: 3rem;
}

.fullsize-container {
  width: 100vw;
  height: 100vh;
  background: #ebebeb;
  background-position-x: center;
  background-size: cover;
  z-index: 1;
  padding: 0pt;
}

.content-div {
  width: 80%;
  height: 100vh;
  display: block;
}

.container-row {
  margin-top: 0rem;
  width: 70%;
}

.phone-container {
  align-content: right;
  width: 50%;
  height: 90%;
  margin: 10%;
}

.phone {
  width: auto;
  height: 100%;
  background-image: url("/sensorphone.png");
  background-position: center;
  background-size: contain;
}

.phone-api {
  width: auto;
  height: 100%;
  background-image: url("/sensorphone-api.png");
  background-position: center;
  background-size: contain;
  -webkit-animation: scaleBreath 10s ease-out infinite normal;
  -moz-animation: scaleBreath 10s ease-out infinite normal;
  -ms-animation: scaleBreath 10s ease-out infinite normal;
  -o-animation: scaleBreath 10s ease-out infinite normal;
  animation: scaleBreath 10s ease-out infinite normal;
}

.button-breathing {
  -webkit-animation: colorBreath 5s ease-out infinite normal;
  -moz-animation: colorBreath 5s ease-out infinite normal;
  -ms-animation: colorBreath 5s ease-out infinite normal;
  -o-animation: colorBreath 5s ease-out infinite normal;
  animation: colorBreath 5s ease-out infinite normal;
}

.hidesmall {
  display: inline-flex;
  display: -ms-inline-flexbox;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
}
.zfix {
  z-index: 10;
}
@media only screen and (max-width: 800px) {
  .container-row {
    margin-top: 7rem;
    width: 90%;
    margin: 7rem auto;
  }

  .pcc {
    display: none;
  }

  .phone-container {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1400px) {
  .phone-container {
    width: 90% !important;
  }
}

@-webkit-keyframes colorBreath {
  0% {
    background-color: #7ca6d8;
  }

  10% {
    background-color: #97bae5;
  }

  30% {
    background-color: #bdd5f1;
  }

  50% {
    background-color: #e3f0ff;
    color: #bdd5f1;
  }

  70% {
    background-color: #bdd5f1;
  }

  80% {
    background-color: #97bae5;
  }

  100% {
    background-color: #7ca6d8;
  }
}

@-webkit-keyframes scaleBreath {
  0% {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
  }

  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  75% {
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
  }

  100% {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
  }
}
</style>