<template>
  <div class="text-center text-wrap">
    <h1 class="display-2">{{ $t("landing.key.title").toUpperCase() }}</h1>
    <v-row align-content="space-around" class="mt-8">
      <v-col cols="2">
        <v-img
          max-height="50"
          max-width="50"
          src="/icons/sensor-icon.png"
        ></v-img>
      </v-col>
      <v-col align-self="center">
        <h2 style="text-align: left">{{ $t("landing.key.1") }}</h2>
        <p style="text-align: left">{{ $t("landing.key.description.1") }}</p>
      </v-col>
    </v-row>

    <v-row align-content="space-around" class="mt-8">
      <v-col cols="2">
        <v-img
          max-height="50"
          max-width="50"
          src="/icons/dashboard.png"
        ></v-img>
      </v-col>
      <v-col align-self="center">
        <h2 style="text-align: left">{{ $t("landing.key.2") }}</h2>
        <p style="text-align: left">{{ $t("landing.key.description.2") }}</p>
      </v-col>
    </v-row>

    <v-row align-content="space-around" class="mt-8">
      <v-col cols="2">
        <v-img max-height="50" max-width="50" src="/icons/graph.png"></v-img>
      </v-col>
      <v-col align-self="center">
        <h2 style="text-align: left">{{ $t("landing.key.3") }}</h2>
        <p style="text-align: left">{{ $t("landing.key.description.3") }}</p>
      </v-col>
    </v-row>

    <v-row align-content="space-around" class="mt-8">
      <v-col cols="2">
        <v-img
          max-height="50"
          max-width="50"
          src="/icons/fire-alarm.png"
        ></v-img>
      </v-col>
      <v-col align-self="center">
        <h2 style="text-align: left">{{ $t("landing.key.4") }}</h2>
        <p style="text-align: left">{{ $t("landing.key.description.4") }}</p>
      </v-col>
    </v-row>

    <v-row align-content="space-around" class="mt-8">
      <v-col cols="2">
        <v-img max-height="50" max-width="50" src="/icons/staff.png"></v-img>
      </v-col>
      <v-col align-self="center">
        <h2 style="text-align: left">{{ $t("landing.key.5") }}</h2>
        <p style="text-align: left">{{ $t("landing.key.description.5") }}</p>
      </v-col>
    </v-row>

    <v-row align-content="space-around" class="mt-8">
      <v-col cols="2">
        <v-img max-height="50" max-width="50" src="/icons/script.png"></v-img>
      </v-col>
      <v-col align-self="center">
        <h2 style="text-align: left">{{ $t("landing.key.6") }}</h2>
        <p style="text-align: left">{{ $t("landing.key.description.6") }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "KeyFeatures",
};
</script>

<style scoped>
.mh100 {
  max-height: 75px !important;
}
</style>