<template>
  <div class="text-center text-wrap">
    <h1 class="display-2">{{ $t("landing.help.title").toUpperCase() }}</h1>
    <br />
    <v-row align-content="space-around" class="mt-8">
      <v-col cols="2">
        <v-img max-height="50" max-width="50" src="/icons/sensor-q.png"></v-img>
      </v-col>
      <v-col align-self="center">
        <h2 style="text-align: left">{{ $t("landing.help.sensors.title") }}</h2>
        <p style="text-align: left">
          {{ $t("landing.help.sensors.description") }}

          <a
            style="text-decoration: none; text-align: left"
            class="so-accent"
            target="_blank"
            href="https://www.eyeo.se/IOT-AB%20produktkatalog%202019.pdf"
            >{{ $t("landing.help.sensors.store") }}</a
          >
        </p>
      </v-col>
    </v-row>

    <v-row align-content="space-around" class="mt-8">
      <v-col cols="2">
        <v-img max-height="50" max-width="50" src="/icons/api.png"></v-img>
      </v-col>
      <v-col align-self="center">
        <h2 style="text-align: left">{{ $t("landing.help.api.title") }}</h2>
        <p style="text-align: left">
          {{ $t("landing.help.api.description") }}

          <a
            :if="SO_API_BASE_URL != undefined"
            style="text-decoration: none; text-align: left"
            class="so-accent"
            target="_blank"
            :href="SO_API_BASE_URL"
            >SENSORONLINE API documentation.
          </a>
        </p>
      </v-col>
    </v-row>

    <v-row align-content="space-around" class="mt-8">
      <v-col cols="2">
        <v-img
          max-height="50"
          max-width="50"
          src="/icons/connection.png"
        ></v-img>
      </v-col>

      <v-col align-self="center">
        <h2 style="text-align: left">{{ $t("landing.help.contact.title") }}</h2>
        <div class="mt-4" style="text-align: left; font-size: 1.3rem">
          <span class="font-weight-light so-main">SENSOR</span>
          <span class="font-weight-bold so-accent">ONLINE</span>
        </div>
        <p style="text-align: left">
          {{ $t("landing.help.contact.siencepark") }}
        </p>
        <p style="text-align: left">{{ $t("landing.help.contact.address") }}</p>
        <p style="text-align: left">{{ $t("landing.help.contact.postal") }}</p>
        <p style="text-align: left">{{ $t("landing.help.contact.country") }}</p>
        <h3 style="text-align: left">
          {{ $t("landing.help.contact.phoneTitle") }}
        </h3>
        <p style="text-align: left">
          {{ $t("landing.help.contact.phonenumber") }}
        </p>
        <p style="text-align: left">
          {{ $t("landing.help.contact.phonenumber2") }}
        </p>
        <h3 style="text-align: left">
          {{ $t("landing.help.contact.emailTitle") }}
        </h3>
        <p style="text-align: left">{{ $t("landing.help.contact.email") }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "HelpPage",
  computed: {
    ...mapState("configuration", ["SO_API_BASE_URL"]),
  },
  methods: {
    ...mapActions("configuration", ["getBaseUrl"]),
  },
  async created() {
    await this.getBaseUrl();
  },
};
</script>

<style scoped>
.so-accent {
  color: #00bfea;
}
.so-main {
  color: #00255f;
}
</style>